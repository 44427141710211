import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RawDocumentFileUploadResponseDTO } from "../../types";
import { auth } from "../../firebaseConfig";
import axios from "axios";

interface RawDocumentsState {
  uploadStatus: "idle" | "loading" | "failed";
  uploadProgress: { [key: string]: number };
  uploadError: string | null;
  fetchStatus: "idle" | "loading" | "failed";
  fetchedExpensesUrls: string[];
  fetchedDocumentsUrls: string[];
  fetchError: string | null;
}

const initialState: RawDocumentsState = {
  uploadStatus: "idle",
  uploadProgress: {},
  uploadError: null,
  fetchStatus: "idle",
  fetchedExpensesUrls: [],
  fetchedDocumentsUrls: [],
  fetchError: null,
};

export const uploadFilesToTeam = createAsyncThunk<
  { teamId: string } & RawDocumentFileUploadResponseDTO,
  { teamId: string; files: FileList },
  { rejectValue: string }
>(
  "rawDocument/uploadFilesToTeam",
  async ({ teamId, files }, { rejectWithValue, dispatch }) => {
    if (!files.length) {
      return rejectWithValue("No files to upload");
    }

    const formData = new FormData();
    Array.from(files).forEach((file) =>
      formData.append("files", file, file.name)
    );

    const uploadRawFileDTO = {
      autoLedgerTeamId: teamId,
    };

    const jsonBlob = new Blob([JSON.stringify(uploadRawFileDTO)], {
      type: "application/json",
    });
    formData.append("uploadRawFileDTO", jsonBlob);

    try {
      const user = auth.currentUser;
      let token = "";
      if (user) {
        token = await user.getIdToken();
      } else {
        return rejectWithValue("User not authenticated");
      }

      const response = await axios.post<RawDocumentFileUploadResponseDTO>(
        "http://localhost:8081/api/team/rawdocuments",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 1)
            );
            dispatch(updateUploadProgress({ teamId, progress }));
          },
        }
      );

      dispatch(resetUploadProgress({ teamId }));

      return { teamId, ...response.data };
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to upload files"
      );
    }
  }
);

export const fetchExpenseImage = createAsyncThunk<
  string,
  { expenseId: string; filename: string },
  { rejectValue: string }
>(
  "rawDocument/fetchExpensePreview",
  async ({ expenseId, filename }, { rejectWithValue }) => {
    try {
      const user = auth.currentUser;
      let token = "";
      if (user) {
        token = await user.getIdToken();
      } else {
        return rejectWithValue("User not authenticated");
      }

      const response = await axios.get<Blob>(
        `http://localhost:8081/api/team/rawdocuments/images/expense/${expenseId}/${filename}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );

      return URL.createObjectURL(response.data);
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch expense preview"
      );
    }
  }
);

export const fetchDocumentImage = createAsyncThunk<
  string,
  { documentId: string; filename: string },
  { rejectValue: string }
>(
  "rawDocument/fetchDocumentPreview",
  async ({ documentId, filename }, { rejectWithValue }) => {
    try {
      const user = auth.currentUser;
      let token = "";
      if (user) {
        token = await user.getIdToken();
      } else {
        return rejectWithValue("User not authenticated");
      }

      const response = await axios.get<Blob>(
        `http://localhost:8081/api/team/rawdocuments/images/document/${documentId}/${filename}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );

      return URL.createObjectURL(response.data);
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch document preview"
      );
    }
  }
);

const rawDocumentSlice = createSlice({
  name: "rawDocument",
  initialState,
  reducers: {
    updateUploadProgress(
      state,
      action: PayloadAction<{ teamId: string; progress: number }>
    ) {
      state.uploadProgress[action.payload.teamId] = action.payload.progress;
    },
    resetUploadProgress(state, action: PayloadAction<{ teamId: string }>) {
      delete state.uploadProgress[action.payload.teamId];
    },
    clearFetchedExpenses(state) {
      state.fetchedExpensesUrls = [];
    },
    clearFetchedDocuments(state) {
      state.fetchedDocumentsUrls = [];
    },
    setFileUploadError(state, action: PayloadAction<string>) {
      state.uploadError = action.payload;
    },
    clearFileUploadError(state) {
      state.uploadError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFilesToTeam.pending, (state) => {
        state.uploadStatus = "loading";
        state.uploadError = null;
      })
      .addCase(uploadFilesToTeam.fulfilled, (state) => {
        state.uploadStatus = "idle";
      })
      .addCase(uploadFilesToTeam.rejected, (state, action) => {
        state.uploadStatus = "failed";
        state.uploadError = action.payload ?? null;
      })
      .addCase(fetchExpenseImage.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(fetchExpenseImage.fulfilled, (state, action) => {
        state.fetchStatus = "idle";
        state.fetchedExpensesUrls.push(action.payload); // Corrected here
      })
      .addCase(fetchExpenseImage.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.payload ?? null;
      })
      .addCase(fetchDocumentImage.pending, (state) => {
        state.fetchStatus = "loading";
        state.fetchError = null;
      })
      .addCase(fetchDocumentImage.fulfilled, (state, action) => {
        state.fetchStatus = "idle";
        state.fetchedDocumentsUrls.push(action.payload);
      })
      .addCase(fetchDocumentImage.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.payload ?? null;
      });
  },
});

export const {
  updateUploadProgress,
  resetUploadProgress,
  clearFetchedExpenses,
  clearFetchedDocuments,
  setFileUploadError,
  clearFileUploadError,
} = rawDocumentSlice.actions;

export default rawDocumentSlice.reducer;
