import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import apiClient from "../../api/apiClient";
import { CreateInvitationDTO, InvitationDTO } from "../../types";

interface InvitationState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  invitationData: InvitationDTO | null;
}

const initialState: InvitationState = {
  status: "idle",
  error: null,
  invitationData: null,
};

export const createInvitation = createAsyncThunk<
  InvitationDTO,
  CreateInvitationDTO,
  { state: RootState; rejectValue: string }
>("invitations/createInvitation", async (payload, { rejectWithValue }) => {
  try {
    const response = await apiClient.post(`/api/invitation/v1`, payload);
    return response.data;
  } catch (error: any) {
    console.error("Network error during invitation creation:", error);
    return rejectWithValue(
      error.response?.data?.message || "Failed to create invitation."
    );
  }
});

export const getInvitation = createAsyncThunk<
  InvitationDTO,
  { code: string },
  { rejectValue: string }
>("invitations/getInvitation", async ({ code }, { rejectWithValue }) => {
  try {
    const response = await apiClient.get(`/api/invitation/v1/${code}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch the invitation."
    );
  }
});

export const consumeInvitation = createAsyncThunk<
  string,
  string,
  { rejectValue: string }
>("invitations/consumeInvitation", async (code, { rejectWithValue }) => {
  try {
    const response = await apiClient.put(`/api/invitation/v1/${code}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to accept invitation."
    );
  }
});

export const deleteInvitation = createAsyncThunk<
  string,
  string,
  { rejectValue: string }
>("invitations/deleteInvitation", async (code, { rejectWithValue }) => {
  try {
    const response = await apiClient.delete(`/api/invitation/v1/${code}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to delete the invitation."
    );
  }
});

const invitationSlice = createSlice({
  name: "invitations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createInvitation.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createInvitation.fulfilled, (state) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(createInvitation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(getInvitation.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.invitationData = null;
      })
      .addCase(getInvitation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.invitationData = action.payload;
      })
      .addCase(getInvitation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
        state.invitationData = null;
      })
      .addCase(consumeInvitation.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(consumeInvitation.fulfilled, (state) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(consumeInvitation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(deleteInvitation.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteInvitation.fulfilled, (state) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(deleteInvitation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export default invitationSlice.reducer;
