import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../components/Loader/Loader";
import {
  selectAuthStatus,
  selectIsAuthenticated,
} from "../store/selectors/authSelectors";

const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const status = useSelector(selectAuthStatus);

  if (status === "loading") {
    return <Loader message="Laddar..." />;
  }

  return isAuthenticated ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;
