import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./TeamOverviewPage.css";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { logout } from "../../store/slices/authSlice";
import { fetchUser } from "../../store/slices/userSlice";
import Loader from "../../components/Loader/Loader";
import { getMemberText } from "../utils/helpers";

const TeamOverviewPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { status } = useSelector((state: RootState) => state.auth);
  const { user } = useSelector((state: RootState) => state.users);
  const [showAll, setShowAll] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // console.log(user);

  useEffect(() => {
    if (!user && status !== "loading") {
      // console.log("fetching data 1");
      const fetchData = async () => {
        try {
          await dispatch(fetchUser()).unwrap();
        } catch (err: any) {
          if (err?.response?.status === 401) {
            dispatch(logout());
            navigate("/");
          } else {
            setError(err.message);
          }
        }
      };
      // console.log("fetching data");
      fetchData();
      // console.log(user);
    }
  }, [dispatch, user, status, navigate]);

  if (!user) {
    return <Loader message="Laddar teams..." />;
  }

  const handleCreateTeam = () => {
    navigate("/create-team");
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleTeamClick = (teamSlug: string) => {
    navigate(`/team-room/${teamSlug}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent, handler: () => void) => {
    if (event.key === "Enter") {
      handler();
    }
  };

  const visibleTeams = showAll
    ? user.teams ?? []
    : (user.teams ?? []).slice(0, 3);

  const handleInviteClick = () => {
    navigate("/pending-invites");
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="team-overview-page">
      <Header />
      <div className="team-overview-content">
        <h1 className="team-overview-title">👋 Välkommen tillbaka!</h1>
        <div className="team-overview-container">
          <div className="team-overview-list-title">
            <span>
              {user.teams && user.teams.length > 0
                ? `Teams för ${user.name}`
                : "Du ingår inte i något team ännu"}
            </span>
            <div
              className="invite-bell"
              tabIndex={0}
              aria-label="Pending invites"
              onClick={handleInviteClick}
              onKeyDown={(e) => handleKeyDown(e, handleInviteClick)}
              title={`Du är inbjuden till ${
                user.pendingInvites?.length || 0
              } team, se mail`}
            >
              <FontAwesomeIcon icon={faBell} className="bell-icon" />
              {user.pendingInvites?.length > 0 && (
                <span className="red-dot" aria-label="Pending invites"></span>
              )}
            </div>
          </div>
          {visibleTeams.map((team) => (
            <div
              key={team.id}
              className="team-item"
              tabIndex={0}
              onClick={() => handleTeamClick(team.slug)}
              onKeyDown={(e) =>
                handleKeyDown(e, () => handleTeamClick(team.slug))
              }
            >
              <div className="overview-team-logo">
                {team.name[0].toUpperCase()}
              </div>
              <div className="team-information">
                <div className="team-name">{team.name}</div>
                <div className="muted-text">
                  {team.users.length} {getMemberText(team.users.length)}
                </div>
              </div>
              <FontAwesomeIcon
                icon={faArrowRightLong}
                className="team-arrow"
                aria-label={`Go to ${team.name} team`}
              />
            </div>
          ))}
          {user.teams && user.teams.length > 3 && (
            <div
              className="show-more"
              tabIndex={0}
              onClick={toggleShowAll}
              onKeyDown={(e) => handleKeyDown(e, toggleShowAll)}
            >
              {showAll ? (
                <>
                  Visa färre team <FontAwesomeIcon icon={faChevronUp} />
                </>
              ) : (
                <>
                  {user.teams.length - 3} fler team{" "}
                  <FontAwesomeIcon icon={faChevronDown} />
                </>
              )}
            </div>
          )}
        </div>
        <div className="new-team-prompt">
          <span>Vill du använda AutoLedger med ett annat team?</span>
          <Button
            variant="primary"
            className="create-team-button"
            onClick={handleCreateTeam}
          >
            Skapa ett nytt team
          </Button>
        </div>
        {error && (
          <div className="error-message">
            <span className="error-text">{error}</span>
          </div>
        )}
        <div className="user-exists-message">
          <span className="muted-text">Ser du inte ditt team? </span>
          <Link to="#" className="login-button-link" onClick={handleLogout}>
            Försök med ett annat konto
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TeamOverviewPage;
