import React, { useState } from "react";
import "./ModalInvitation.css";
import {
  faXmark,
  faCircleCheck,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "../../../../components/Button/IconButton/IconButton";
import InputField from "../../../../components/Input/InputField/InputFIeld";
import Checkbox from "../../../../components/Input/Checkbox/Checkbox";
import Button from "../../../../components/Button/Button";

interface ModalInvitationProps {
  onClose: () => void;
  onSendInvite: (email: string, shouldBeAdmin: boolean) => Promise<void>;
  isAdmin: boolean;
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ModalInvitation: React.FC<ModalInvitationProps> = ({
  onClose,
  onSendInvite,
  isAdmin,
}) => {
  const [emailInput, setEmailInput] = useState("");
  const [shouldBeAdmin, setShouldBeAdmin] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const resetForm = () => {
    setEmailInput("");
    setShouldBeAdmin(false);
    setError(null);
    setSuccess(false);
    setLoading(false);
    setSuccessMessage(null);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(event.target.value);
    setError(null);
  };

  const handleCheckboxChange = () => {
    setShouldBeAdmin(!shouldBeAdmin);
  };

  const handleSendInvite = () => {
    if (!emailRegex.test(emailInput)) {
      setError("Ogiltig mailadress.");
      return;
    }

    setLoading(true);
    setError(null);

    onSendInvite(emailInput, shouldBeAdmin)
      .then(() => {
        setSuccessMessage(`Inbjudan skickad till ${emailInput}`);
        setSuccess(true);
        setLoading(false);
      })
      .catch(() => {
        setError("Misslyckades att skicka inbjudan.");
        setLoading(false);
      });
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-invitation-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-invitation-header">
          <h3>Bjud in en vän</h3>
          <IconButton
            icon={faXmark}
            onClick={onClose}
            ariaLabel={"close-button"}
          />
        </div>
        <div className="modal-invitation-center">
          {loading && (
            <div className="invitation-loader">
              <FontAwesomeIcon icon={faSpinner} spin />
              <span>Skickar...</span>
            </div>
          )}
          {!loading && !success && (
            <>
              <span className="muted-text">Till:</span>
              <InputField
                label={""}
                placeholder="name@domain.com"
                required={true}
                value={emailInput}
                onChange={handleEmailChange}
              />
              {error && <p className="error-text">{error}</p>}
              <Checkbox
                id="admin-checkbox"
                label="Bjud in som admin"
                checked={shouldBeAdmin}
                onChange={handleCheckboxChange}
                ariaLabel="Admin checkbox"
              />
              {!isAdmin && (
                <span className="admin-warning">
                  Endast admins kan bjuda in medlemmar.
                </span>
              )}
            </>
          )}
          {success && successMessage && (
            <div className="success-message">
              <FontAwesomeIcon icon={faCircleCheck} className="success-icon" />
              <span>{successMessage}</span>
            </div>
          )}
        </div>
        <div className="modal-invitation-footer">
          <Button
            variant="secondary"
            onClick={onClose}
            altText="Close modal button"
          >
            Stäng
          </Button>
          {!loading && (
            <Button
              variant="primary"
              onClick={success ? resetForm : handleSendInvite}
              disabled={emailInput.trim() === "" || !isAdmin}
              altText="Send invitation button"
              title={!isAdmin ? "Denna åtgärd kräver administratörsskap" : ""}
            >
              {success ? "Skicka ny inbjudan" : "Skicka inbjudan"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalInvitation;
