import React, { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import "./Dropdown.css";

interface DropdownOption {
  name: string;
  logo?: string;
  icon?: IconDefinition;
}

interface DropdownProps {
  isVisible: boolean;
  options: DropdownOption[];
  onOptionSelect: (option: string) => void;
  parentRef: React.RefObject<HTMLDivElement>;
  header?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  isVisible,
  options,
  onOptionSelect,
  parentRef,
  header,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (isVisible && parentRef.current && dropdownRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [isVisible, parentRef]);

  if (!isVisible) return null;

  return (
    <div
      ref={dropdownRef}
      className="dropdown-container"
      style={{ top: `${position.top}px`, left: `${position.left}px` }}
    >
      <div className="dropdown-header">
        <span className="header-text">{header}</span>
      </div>
      <ul className="dropdown-list">
        {options.map((option, index) => (
          <li
            key={index}
            className="dropdown-item"
            onClick={() => onOptionSelect(option.name)}
          >
            <div className="dropdown-icon-container">
              {option.icon ? (
                <FontAwesomeIcon icon={option.icon} className="dropdown-icon" />
              ) : option.logo ? (
                <img
                  src={option.logo}
                  alt={option.name}
                  className="dropdown-logo"
                />
              ) : (
                <div className="dropdown-initial">
                  {option.name[0].toUpperCase()}
                </div>
              )}
            </div>
            <span className="team-name">{option.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
