import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface ResourceState {
  isAdminInTeams: { [teamId: string]: boolean };
  hasUserRightsInTeams: { [teamId: string]: boolean };
}

const initialState: ResourceState = {
  isAdminInTeams: {},
  hasUserRightsInTeams: {},
};

const resourceSlice = createSlice({
  name: "resource",
  initialState,
  reducers: {
    setAdminRights(
      state,
      action: PayloadAction<{ teamId: string; isAdmin: boolean }>
    ) {
      state.isAdminInTeams[action.payload.teamId] = action.payload.isAdmin;
    },
    setUserRights(
      state,
      action: PayloadAction<{ teamId: string; hasRights: boolean }>
    ) {
      state.hasUserRightsInTeams[action.payload.teamId] =
        action.payload.hasRights;
    },
    resetRights(state) {
      state.isAdminInTeams = {};
      state.hasUserRightsInTeams = {};
    },
  },
});

export const { setAdminRights, setUserRights, resetRights } =
  resourceSlice.actions;

export default resourceSlice.reducer;

export const selectIsAdminInTeam = (state: RootState, teamId: string) =>
  state.resources.isAdminInTeams[teamId] || false;

export const selectHasUserRightsInTeam = (state: RootState, teamId: string) =>
  state.resources.hasUserRightsInTeams[teamId] || false;
