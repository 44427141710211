import React from "react";
import styles from "./Button.module.css";

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  variant?: "primary" | "secondary" | "google-primary" | "google-secondary";
  className?: string;
  disabled?: boolean;
  ariaLabel?: string;
  altText?: string;
  title?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  type = "button",
  variant = "primary",
  className = "",
  disabled = false,
  ariaLabel,
  altText,
  title,
}) => {
  const isGoogleVariant = variant?.includes("google");
  const buttonClass = `${styles.button} ${
    styles[variant || "primary"]
  } ${className}`;

  return (
    <button
      onClick={onClick}
      type={type}
      className={buttonClass}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      title={title} // Set title attribute
    >
      {isGoogleVariant && (
        <img
          src={
            "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
          }
          alt={altText || "Google logo"}
          className={styles.icon}
        />
      )}
      {children}
    </button>
  );
};

export default Button;
