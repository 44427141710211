import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store/store";
import Header from "../../components/Header/Header";
import Wizard from "../../components/Wizard/Wizard";
import "./CreateTeamPage.css";
import SimpleStep from "./Steps/SimpleStep/SimpleStep";
import Loader from "../../components/Loader/Loader";
import { createTeam } from "../../store/slices/teamSlice";
import { addTeamToUser } from "../../store/slices/userSlice";
import {
  selectAuthStatus,
  selectUser,
} from "../../store/selectors/authSelectors";
import {
  setAdminRights,
  setUserRights,
} from "../../store/slices/resourceSlice";
import { SystemUserDTO } from "../../types";

const CreateTeamPage: React.FC = () => {
  const [teamName, setTeamName] = useState("");
  const [acceptedAutodomainName, setAcceptedAutodomainName] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const authStatus = useSelector(selectAuthStatus);
  const teamStatus = useSelector((state: RootState) => state.teams.status);

  useEffect(() => {
    if (!user && authStatus !== "loading") {
      navigate("/", { replace: true });
    }
  }, [user, authStatus, navigate]);

  const validateStep = (currentStepIndex: number) => {
    let isValid = true;

    if (currentStepIndex === 0 && !teamName) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        teamName: "Teamnamn är obligatoriskt.",
      }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, teamName: "" }));
    }

    return isValid;
  };

  const resetForm = () => {
    setTeamName("");
    setAcceptedAutodomainName("");
    setErrors({});
  };

  const handleFormSubmit = async () => {
    if (teamName) {
      try {
        const result = await dispatch(
          createTeam({
            teamName,
            acceptedAutodomainName: acceptedAutodomainName,
          })
        ).unwrap();

        if (result) {
          dispatch(addTeamToUser(result));

          dispatch(
            setAdminRights({
              teamId: result.id,
              isAdmin: result.admins.some(
                (admin: SystemUserDTO) => admin.id === user!.id
              ),
            })
          );
          dispatch(
            setUserRights({
              teamId: result.id,
              hasRights: result.users.some(
                (u: SystemUserDTO) => u.id === user!.id
              ),
            })
          );

          resetForm();
          navigate("/team-overview");
        } else {
          console.error("Failed to create team.");
        }
      } catch (error) {
        console.error("Failed to create team:", error);
      }
    } else {
      console.error("Required fields are missing", { teamName });
    }
  };

  const steps = [
    <SimpleStep
      formData={{ teamName, acceptedAutodomainName }}
      updateFormData={({ teamName, acceptedAutodomainName }) => {
        if (teamName !== undefined) setTeamName(teamName);
        if (acceptedAutodomainName !== undefined)
          setAcceptedAutodomainName(acceptedAutodomainName);
      }}
      errors={errors}
    />,
  ];

  return (
    <div className="create-team-page">
      <Header />
      {teamStatus === "loading" && <Loader message="Skapar team ..." />}
      <div className="create-team-content">
        <Wizard
          steps={steps}
          onSubmit={handleFormSubmit}
          validateStep={validateStep}
          resetForm={resetForm}
        />
      </div>
    </div>
  );
};

export default CreateTeamPage;
