import React from "react";
import InputField from "../../../../components/Input/InputField/InputFIeld";
import "./SimpleStep.css";

interface StepProps {
  formData: {
    teamName: string;
    acceptedAutodomainName: string;
  };
  updateFormData: (data: any) => void;
  errors: { [key: string]: string };
}

const SimpleStep: React.FC<StepProps> = ({
  formData,
  updateFormData,
  errors,
}) => {
  const handleTeamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData({ teamName: e.target.value });
    if (errors.teamName) {
      errors.teamName = "";
    }
  };

  // const handleAcceptedAutodomainNameChange = (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   updateFormData({ acceptedAutodomainName: e.target.value });
  //   if (errors.acceptedAutodomainName) {
  //     errors.acceptedAutodomainName = "";
  //   }
  // };

  return (
    <div>
      <h1>Berätta mer om ditt team!</h1>
      <div className="form-group">
        <InputField
          label="Företags- eller teamnamn *"
          subLabel="Detta kommer vara namnet på ditt workspace."
          placeholder="Ex: Kameral Ekonomipartner"
          maxLength={50}
          required={true}
          error={errors.teamName}
          value={formData.teamName || ""}
          onChange={handleTeamNameChange}
        />
      </div>
      {/* <div className="form-group">
        <InputField
          label="Bjud in medlemmar"
          subLabel="Du kan välja att bjuda in flera medlemmar med samma domännamn."
          placeholder="Ex: kameral.se"
          maxLength={50}
          required={false}
          error={errors.acceptedAutodomainName}
          value={formData.acceptedAutodomainName || ""}
          onChange={handleAcceptedAutodomainNameChange}
        />
      </div> */}
    </div>
  );
};

export default SimpleStep;
