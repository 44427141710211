import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { AutoLedgerTeamDTO, ExpenseDTO, DocumentDTO } from "../../../../types";
import "./NavigationArea.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

type FileItem =
  | (ExpenseDTO & { type: "Expense" })
  | (DocumentDTO & { type: "Document" });

interface NavigationAreaProps {
  selectedTeam: AutoLedgerTeamDTO;
  files: FileItem[];
  onDrop: (event: React.DragEvent) => void;
  onDragOver: (event: React.DragEvent) => void;
  onOpenMembers: () => void;
  onFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  uploadProgress: number;
  uploadStatus: "idle" | "loading" | "failed";
  uploadingFileName: string | null;
  onSelectFile: (file: FileItem) => void;
  size: "normal" | "small";
}

const NavigationArea: React.FC<NavigationAreaProps> = ({
  selectedTeam,
  files,
  onDrop,
  onDragOver,
  onOpenMembers,
  onFileInputChange,
  onSelectFile,
  uploadStatus,
  uploadProgress,
  size = "normal",
}) => {
  const [selectedFile, setSelectedFile] = useState<FileItem | null>(null);
  const [documentsOpen, setDocumentsOpen] = useState(false);
  const [expensesOpen, setExpensesOpen] = useState(false);
  const uploadError = useSelector(
    (state: RootState) => state.files.uploadError
  );

  const containerClassName = `members-navigation-area ${
    size === "small" ? "small-width" : "normal-width"
  }`;

  const handleFileClick = (file: FileItem) => {
    setSelectedFile(file);
    onSelectFile(file);
  };

  const renderMemberIcons = () => {
    const totalMembers =
      selectedTeam.users.length +
      (selectedTeam.pendingInvitations?.length || 0);
    return (
      <>
        {totalMembers > 0 && (
          <span
            className="member-icon"
            aria-label={`Member: ${
              selectedTeam.users[0]?.name || "No members"
            }`}
          >
            <FontAwesomeIcon icon={faUser} />
          </span>
        )}
        <span
          className="member-count"
          aria-label={`Total members and pending invitations: ${totalMembers}`}
        >
          {totalMembers}
        </span>
      </>
    );
  };

  const renderFiles = () => {
    const documents = files.filter(
      (file): file is DocumentDTO & { type: "Document" } =>
        file.type === "Document"
    );
    const expenses = files.filter(
      (file): file is ExpenseDTO & { type: "Expense" } =>
        file.type === "Expense"
    );

    const toggleDocuments = () => setDocumentsOpen(!documentsOpen);
    const toggleExpenses = () => setExpensesOpen(!expensesOpen);

    return (
      <>
        <div className="file-group">
          <div className="file-group-header" onClick={toggleDocuments}>
            <strong>Dokument</strong>
            <FontAwesomeIcon
              icon={documentsOpen ? faChevronUp : faChevronDown}
            />
          </div>
          {documentsOpen && (
            <ul className="files-list">
              {documents.length > 0 ? (
                documents.map((file, index) => (
                  <li
                    key={`document-${index}`}
                    onClick={() => handleFileClick(file)}
                    className={selectedFile?.id === file.id ? "selected" : ""}
                  >
                    {file.id}
                  </li>
                ))
              ) : (
                <span className="muted-text files-list empty-list">
                  Inga dokument
                </span>
              )}
            </ul>
          )}
        </div>
        <div className="file-group">
          <div className="file-group-header" onClick={toggleExpenses}>
            <strong>Kostnader</strong>
            <FontAwesomeIcon
              icon={expensesOpen ? faChevronUp : faChevronDown}
            />
          </div>
          {expensesOpen && (
            <ul className="files-list">
              {expenses.length > 0 ? (
                expenses.map((file, index) => (
                  <li
                    key={`expense-${index}`}
                    onClick={() => handleFileClick(file)}
                    className={selectedFile?.id === file.id ? "selected" : ""}
                  >
                    {file.vendor?.name || "Okänd leverantör"}
                  </li>
                ))
              ) : (
                <span className="muted-text files-list empty-list">
                  Inga kostnader
                </span>
              )}
            </ul>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={containerClassName}>
      <div className="team-title">
        <span
          className="team-name"
          aria-label={selectedTeam.name}
          title={selectedTeam.name}
        >
          {selectedTeam.name}
        </span>

        <button
          onClick={onOpenMembers}
          className="members-button"
          aria-label={"Show team members"}
        >
          {renderMemberIcons()}
        </button>
      </div>
      <ul className="files-list">{renderFiles()}</ul>
      {uploadError && <div className="error-message">{uploadError}</div>}
      {uploadStatus === "loading" && (
        <div className="upload-progress">
          <div
            className="upload-progress-bar"
            style={{ width: `${uploadProgress}%` }}
          ></div>
        </div>
      )}
      <div className="file-drop-area" onDrop={onDrop} onDragOver={onDragOver}>
        <input
          type="file"
          multiple
          style={{ display: "none" }}
          id="fileInput"
          onChange={onFileInputChange}
        />
        <label htmlFor="fileInput" className="file-input-label">
          <span>Släpp dina filer här eller klicka för att ladda upp</span>
        </label>
      </div>
      <span className="muted-text helper-text">
        Accepterade filformat: jpeg, png, tiff, och pdf.
      </span>
    </div>
  );
};

export default NavigationArea;
