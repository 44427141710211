import axios from "axios";
import { auth } from "../firebaseConfig";

const apiClient = axios.create({
  baseURL: "https://autoledger-backend.kameral.se",
  // baseURL: "http://localhost:8081",
});

apiClient.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      // console.log(token);
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      auth.signOut().then(() => {
        window.location.href = "/";
      });
    }
    return Promise.reject(error);
  }
);

export default apiClient;
