import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import "./Wizard.css";

interface WizardProps {
  steps: React.ReactNode[];
  onSubmit: () => void;
  validateStep: (currentStepIndex: number) => boolean;
  resetForm: () => void;
}

const Wizard: React.FC<WizardProps> = ({
  steps,
  onSubmit,
  validateStep,
  resetForm,
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const totalSteps = steps.length;
  const navigate = useNavigate();

  const nextStep = () => {
    if (validateStep(currentStepIndex)) {
      if (currentStepIndex < totalSteps - 1) {
        setCurrentStepIndex(currentStepIndex + 1);
      } else {
        onSubmit();
      }
    }
  };

  const prevStep = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const goBack = () => {
    if (currentStepIndex === 0) {
      resetForm();
    }
    navigate(-1);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      resetForm();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [resetForm]);

  const CurrentStep = steps[currentStepIndex];

  return (
    <div className="wizard">
      <div className="wizard-progress">
        <div className="muted-text">
          Steg {currentStepIndex + 1} av {totalSteps} (Fler steg kommer!)
        </div>
        <progress value={currentStepIndex + 1} max={totalSteps}></progress>
      </div>

      <div className="wizard-step-content">
        {React.cloneElement(CurrentStep as React.ReactElement, {
          next: nextStep,
          prev: prevStep,
        })}
      </div>

      <div className="wizard-navigation">
        {currentStepIndex === 0 ? (
          <Button variant="secondary" onClick={goBack}>
            Tillbaka
          </Button>
        ) : (
          <Button variant="secondary" onClick={prevStep}>
            Föregående
          </Button>
        )}
        <Button variant="primary" onClick={nextStep}>
          {currentStepIndex === totalSteps - 1 ? "Lägg till team" : "Nästa"}
        </Button>
      </div>
    </div>
  );
};

export default Wizard;
