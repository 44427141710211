import React, { useState } from "react";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../firebaseConfig";
import "./LoginPage.css";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchUser } from "../../store/slices/userSlice";
import { AppDispatch } from "../../store/store";
import Loader from "../../components/Loader/Loader";

const LoginPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleLogin = async () => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const userCredential = await signInWithPopup(auth, googleProvider);
      const user = userCredential.user;

      if (user) {
        await user.getIdToken(true);
        await dispatch(fetchUser()).unwrap();
        navigate("/team-overview", { replace: true });
      }
    } catch (error) {
      console.error("Login failed:", error);
      setErrorMessage("Inloggningen misslyckades. Försök igen.");
    } finally {
      setLoading(false);
    }
  };

  const rightColumnContent = (
    <div>
      <span className="muted-text">Ny användare?</span>
      <Link to="/create-account" className="create-account-link">
        Skapa ett konto
      </Link>
    </div>
  );

  return (
    <div className="login-page">
      <Header rightColumnContent={loading ? "" : rightColumnContent} />
      {loading ? (
        <Loader message="Loggar in..." />
      ) : (
        <div className="login-page-content">
          <h1 className="login-title">Logga in på Kameral AutoLedger</h1>
          {errorMessage && <p className="error-text">{errorMessage}</p>}
          <Button
            variant="google-primary"
            onClick={handleLogin}
            altText="Google logo"
            className="login-button"
          >
            Logga in med Google
          </Button>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
