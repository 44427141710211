import React, { useEffect, useState } from "react";
import "./CollaborationArea.css";
import {
  faSearchPlus,
  faSearchMinus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../components/Button/IconButton/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import {
  fetchExpenseImage,
  fetchDocumentImage,
  clearFetchedDocuments,
  clearFetchedExpenses,
} from "../../../../store/slices/rawDocumentsSlice";

import { ExpenseDTO, DocumentDTO } from "../../../../types";

type SelectedFile =
  | (ExpenseDTO & { type: "Expense" })
  | (DocumentDTO & { type: "Document" })
  | null;

interface CollaborationAreaProps {
  selectedFile: SelectedFile;
  setIsNavigationAreaShrunk: (isShrunk: boolean) => void;
}

const CollaborationArea: React.FC<CollaborationAreaProps> = ({
  selectedFile,
  setIsNavigationAreaShrunk,
}) => {
  const [isZoomed, setZoomed] = useState<boolean | undefined>(undefined);
  const dispatch = useDispatch();
  const fetchedExpenseUrls = useSelector(
    (state: RootState) => state.files.fetchedExpensesUrls
  );
  const fetchedDocumentUrls = useSelector(
    (state: RootState) => state.files.fetchedDocumentsUrls
  );
  const fetchStatus = useSelector(
    (state: RootState) => state.files.fetchStatus
  );

  useEffect(() => {
    if (typeof isZoomed !== "undefined") {
      setIsNavigationAreaShrunk(isZoomed);
    }
  }, [isZoomed, setIsNavigationAreaShrunk]);

  useEffect(() => {
    if (selectedFile && selectedFile.previewImageUrls.length > 0) {
      if (selectedFile.type === "Expense") {
        dispatch(clearFetchedExpenses());
        selectedFile.previewImageUrls.forEach((url) => {
          dispatch<any>(
            fetchExpenseImage({ expenseId: selectedFile.id, filename: url })
          );
        });
      } else if (selectedFile.type === "Document") {
        dispatch(clearFetchedDocuments());
        selectedFile.previewImageUrls.forEach((url) => {
          dispatch<any>(
            fetchDocumentImage({ documentId: selectedFile.id, filename: url })
          );
        });
      }
    }
  }, [selectedFile, dispatch]);

  const showExpenseImages =
    selectedFile &&
    selectedFile.type === "Expense" &&
    fetchedExpenseUrls.length > 0;

  const showDocumentImages =
    selectedFile &&
    selectedFile.type === "Document" &&
    fetchedDocumentUrls.length > 0;

  return (
    <div className="collaboration-area">
      <div className="data-container">
        {selectedFile && (
          <div className="data-table">
            {selectedFile.type === "Expense" ? (
              <>
                <div className="data-row">
                  <div className="data-header">Fakturainformation</div>
                </div>
                <div className="data-row">
                  <div className="data-cell">Leverantör</div>
                  <div className="data-cell">
                    {selectedFile.vendor?.name || "Unknown Vendor"}
                  </div>
                </div>
                <div className="data-row">
                  <div className="data-cell">Fakturadatum</div>
                  <div className="data-cell">
                    {selectedFile.invoiceDate || "N/A"}
                  </div>
                </div>
                <div className="data-row">
                  <div className="data-cell">Fakturanummer</div>
                  <div className="data-cell">
                    {selectedFile.invoiceReceiptID || "N/A"}
                  </div>
                </div>
                <div className="data-row">
                  <div className="data-cell">Totalbelopp att betala</div>
                  <div className="data-cell">
                    {selectedFile.lineItems
                      ? selectedFile.lineItems.reduce(
                          (sum, item) => sum + item.totalPrice,
                          0
                        ) +
                        " " +
                        (selectedFile.lineItems[0]?.currency || "")
                      : "N/A"}
                  </div>
                </div>
                {selectedFile.lineItems &&
                  selectedFile.lineItems.length > 0 && (
                    <div className="data-row">
                      <div className="data-header">
                        <strong>Artiklar</strong>
                      </div>
                    </div>
                  )}
                {selectedFile.lineItems?.map((item, index) => (
                  <div className="data-row" key={index}>
                    <div className="data-cell">{item.itemName}</div>
                    <div className="data-cell">
                      {item.quantity} @ {item.unitPrice} {item.currency} (Total:{" "}
                      {item.totalPrice} {item.currency})
                    </div>
                  </div>
                ))}
              </>
            ) : selectedFile.type === "Document" ? (
              <>
                <div className="data-row">
                  <div className="data-header">Identifierad data</div>
                </div>
                <div className="data-row">
                  <div className="data-cell">DokumentID</div>
                  <div className="data-cell">{selectedFile.id}</div>
                </div>
                <div className="data-row">
                  <div className="data-cell">PNGs</div>
                  <div className="data-cell">
                    <ul>
                      {selectedFile.previewImageUrls.map((url, index) => (
                        <li key={index}>{url}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        )}
      </div>
      <div
        className={` files-container a4-document ${
          isZoomed ? "actual" : "fit"
        }`}
      >
        {fetchStatus === "loading" &&
        ((selectedFile?.type === "Expense" &&
          fetchedExpenseUrls.length === 0) ||
          (selectedFile?.type === "Document" &&
            fetchedDocumentUrls.length === 0)) ? (
          <div className="loader-container">
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            <span>Läser in fil...</span>
          </div>
        ) : (
          <>
            {showExpenseImages &&
              fetchedExpenseUrls.map((url, index) => (
                <div
                  key={index}
                  className={`a4-document ${isZoomed ? "actual" : "fit"}`}
                >
                  <div className="page-body">
                    <img
                      src={url}
                      alt={`Fetched expense preview ${index + 1}`}
                    />
                  </div>
                </div>
              ))}
            {showDocumentImages &&
              fetchedDocumentUrls.map((url, index) => (
                <div key={index}>
                  <div className="page-body">
                    <img
                      src={url}
                      alt={`Fetched document preview ${index + 1}`}
                    />
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
      <div className="zoom-controls">
        <IconButton
          icon={faSearchPlus}
          onClick={() => setZoomed(true)}
          ariaLabel="Zoom In"
          active={isZoomed}
        />
        <IconButton
          icon={faSearchMinus}
          onClick={() => setZoomed(false)}
          ariaLabel="Zoom Out"
          active={!isZoomed}
        />
      </div>
    </div>
  );
};

export default CollaborationArea;
