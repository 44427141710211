import React from "react";
import "./InputField.css";

interface CustomInputProps {
  label?: string;
  subLabel?: string;
  placeholder: string;
  maxLength?: number;
  required?: boolean;
  error?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField: React.FC<CustomInputProps> = ({
  label,
  subLabel,
  placeholder,
  maxLength,
  required = false,
  error = "",
  value,
  onChange,
}) => {
  return (
    <div className="custom-input-container">
      <label
        htmlFor={label}
        className={`custom-input-label ${required ? "required" : ""}`}
      >
        {label}
      </label>
      {subLabel ? <div className="muted-text">{subLabel} </div> : ""}
      <div className="input-wrapper">
        <input
          id={label}
          type="text"
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          aria-required={required}
          aria-invalid={!!error}
          className={`custom-input-field ${error ? "input-error" : ""}`}
          onChange={onChange}
          aria-describedby={error ? `error-${label}` : undefined}
        />
        {maxLength && (
          <div className="char-counter">
            {value.length}/{maxLength}
          </div>
        )}
      </div>
      {error && (
        <span id={`error-${label}`} className="error-text" role="alert">
          {error}
        </span>
      )}
    </div>
  );
};

export default InputField;
