import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../api/apiClient";
import { AutoLedgerTeamDTO, CreateAutoLedgerTeamDTO } from "../../types";

interface TeamState {
  teams: AutoLedgerTeamDTO[] | null;
  selectedTeam: AutoLedgerTeamDTO | null;
  status: "idle" | "loading" | "failed";
}

const initialState: TeamState = {
  teams: null,
  selectedTeam: null,
  status: "idle",
};

export const fetchTeamById = createAsyncThunk(
  "teams/fetchTeamById",
  async (teamId: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.get<AutoLedgerTeamDTO>(
        `/api/team/v1/${teamId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch team"
      );
    }
  }
);

export const updateUser = createAsyncThunk(
  "teams/updateUser",
  async (team: AutoLedgerTeamDTO, { rejectWithValue }) => {
    try {
      const response = await apiClient.put(`/api/team/v1`, team);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update team"
      );
    }
  }
);

export const createTeam = createAsyncThunk(
  "teams/createTeam",
  async (
    { teamName, acceptedAutodomainName }: CreateAutoLedgerTeamDTO,
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(`/api/team/v1`, {
        teamName,
        acceptedAutodomainName,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to create team"
      );
    }
  }
);

export const deleteTeamById = createAsyncThunk(
  "teams/deleteTeam",
  async (teamId: string, { rejectWithValue }) => {
    try {
      await apiClient.delete(`/api/team/v1/${teamId}`);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const teamSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    addPendingInvitation(state, action) {
      if (state.selectedTeam) {
        if (!state.selectedTeam.pendingInvitations) {
          state.selectedTeam.pendingInvitations = [];
        }
        state.selectedTeam.pendingInvitations.push(action.payload);
      }
    },
    removePendingInvitation(state, action) {
      if (state.selectedTeam && state.selectedTeam.pendingInvitations) {
        state.selectedTeam.pendingInvitations =
          state.selectedTeam.pendingInvitations.filter(
            (invitation) => invitation.id !== action.payload
          );
      }
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(deleteTeamById.fulfilled, (state, action) => {
        if (state.teams) {
          state.teams = state.teams.filter(
            (team) => team.id !== action.meta.arg
          );
        }
        state.status = "idle";
      })
      .addCase(fetchTeamById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTeamById.fulfilled, (state, action) => {
        state.selectedTeam = action.payload;
        state.status = "idle";
      })
      .addCase(fetchTeamById.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { addPendingInvitation, removePendingInvitation } =
  teamSlice.actions;

export default teamSlice.reducer;
