import React, { useState } from "react";
import "./ModalDeleteTeam.css";
import { faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../components/Button/IconButton/IconButton";
import Button from "../../../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ModalDeleteTeamProps {
  onClose: () => void;
  teamName: string;
  onDeleteTeam: () => Promise<void>;
  isAdmin: boolean;
}

const ModalDeleteTeam: React.FC<ModalDeleteTeamProps> = ({
  onClose,
  teamName,
  onDeleteTeam,
  isAdmin,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDeleteTeam = () => {
    setLoading(true);
    setError(null);

    onDeleteTeam()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setError("Misslyckades att radera teamet.");
        setLoading(false);
      });
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-delete-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-delete-header">
          <h3>Radera "{teamName}"</h3>
          <IconButton
            icon={faXmark}
            onClick={onClose}
            ariaLabel={"close-button"}
          />
        </div>
        <div className="modal-delete-center">
          {loading ? (
            <div className="invitation-loader">
              <FontAwesomeIcon icon={faSpinner} spin />
              <span>Raderar...</span>
            </div>
          ) : (
            <>
              <span>
                Är du säker på att du vill radera detta team? Denna åtgärd kan
                inte ångras.
              </span>
              {!isAdmin && (
                <span className="admin-warning">
                  Denna åtgärd kräver administratörsskap.
                </span>
              )}
            </>
          )}
        </div>
        <div className="modal-delete-footer">
          <Button
            variant="secondary"
            onClick={onClose}
            altText="Close modal button"
          >
            Stäng
          </Button>
          {!loading && (
            <Button
              variant="primary"
              onClick={handleDeleteTeam}
              altText="Delete team button"
              disabled={!isAdmin}
              title={!isAdmin ? "Denna åtgärd kräver administratörsskap" : ""}
            >
              Radera team
            </Button>
          )}
          {error && <p className="error-text">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default ModalDeleteTeam;
