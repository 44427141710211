import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBQeTErVP-nTzQGE1VOwDwzd3hUt5bSb_A",
    authDomain: "kameral-auto-ledger.firebaseapp.com",
    projectId: "kameral-auto-ledger",
    storageBucket: "kameral-auto-ledger.appspot.com",
    messagingSenderId: "698594786357",
    appId: "1:698594786357:web:a6118f95cb5a20510d057c"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };
