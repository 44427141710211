import React from "react";
import "./Checkbox.css";

interface CheckboxProps {
  label?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  ariaLabel?: string;
  id: string;
  required?: boolean;
  showError?: boolean;
  errorMessage?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  ariaLabel,
  id,
  required = false,
  showError = false,
  errorMessage,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <div className="checkbox-container">
      <div className="checkbox-row">
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={handleChange}
          aria-label={ariaLabel || label}
          aria-checked={checked}
          aria-required={required}
          className={showError ? "error-checkbox" : ""}
        />
        {label && (
          <label
            htmlFor={id}
            className={`checkbox-label ${showError ? "error-text" : ""}`}
          >
            {label}
          </label>
        )}
      </div>
      {showError && errorMessage && (
        <span className="error-message" role="alert">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default Checkbox;
