import React, { useState, useRef } from "react";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../firebaseConfig";
import Button from "../../components/Button/Button";
import "./CreateAccountPage.css";
import Header from "../../components/Header/Header";
import Loader from "../../components/Loader/Loader";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createUser } from "../../store/slices/userSlice";
import { AppDispatch } from "../../store/store";
import Checkbox from "../../components/Input/Checkbox/Checkbox";

const CreateAccountPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showError, setShowError] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invitationCode = queryParams.get("invitationCode");

  const handleCreateAccount = async () => {
    if (!termsAccepted) {
      setShowError(true);
      checkboxRef.current?.focus();
      return;
    }

    setShowError(false);
    setLoading(true);

    try {
      await signInWithPopup(auth, googleProvider);
      await dispatch(createUser()).unwrap();

      if (invitationCode) {
        navigate(`/get-started?invitationCode=${invitationCode}`, {
          replace: true,
        });
      } else {
        navigate("/team-overview", { replace: true });
      }
    } catch (error: any) {
      if (error.message === "User already exists") {
        setUserExists(true);
        setTermsAccepted(false);
      } else {
        console.error("Account creation failed:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create-account-page">
      <Header />
      {loading ? (
        <Loader message="Skapar konto..." />
      ) : (
        <div className="create-account-content">
          <h1>Kul att du vill vara med!</h1>
          {userExists && (
            <p>En användare finns redan registrerad med detta konto.</p>
          )}
          <>
            <div className="terms-section">
              <Checkbox
                id="terms-checkbox"
                label="Kryssa i för att godkänna användarvillkor"
                checked={termsAccepted}
                onChange={setTermsAccepted}
                required
                showError={showError}
                errorMessage="Du måste godkänna användarvillkoren för att skapa ett konto."
              />
            </div>
            <Button
              variant="google-primary"
              onClick={handleCreateAccount}
              altText="Google logo"
              className="create-account-button"
            >
              Skapa konto med Google
            </Button>
          </>
          <div className="user-exists-message">
            <span className="muted-text">Har du redan ett konto? </span>
            <Link to="/" className="login-button-link">
              Klicka här om du vill logga in istället
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateAccountPage;
