import { configureStore } from "@reduxjs/toolkit";
import teamSlice from "./slices/teamSlice";
import authSlice from "./slices/authSlice";
import userSlice from "./slices/userSlice";
import resourceSlice from "./slices/resourceSlice";
import invitationSlice from "./slices/invitationSlice";
import rawDocumentSlice from "./slices/rawDocumentsSlice";

const store = configureStore({
  reducer: {
    teams: teamSlice,
    auth: authSlice,
    users: userSlice,
    resources: resourceSlice,
    invitations: invitationSlice,
    files: rawDocumentSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
