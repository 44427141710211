import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./TopNavBar.css";
import { DocumentDTO, ExpenseDTO } from "../../../../types";

type FileItem =
  | (ExpenseDTO & { type: "Expense" })
  | (DocumentDTO & { type: "Document" });

interface TopNavBarProps {
  files: FileItem[];
  onSelectFile: (file: FileItem) => void;
}

const TopNavBar: React.FC<TopNavBarProps> = ({ files, onSelectFile }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    setDropdownVisible(query.length > 0);
  };

  const filteredResults = files.filter(
    (file) =>
      file.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      file.type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleFileSelect = (file: FileItem) => {
    onSelectFile(file);
    setSearchQuery(file.id);
    setDropdownVisible(false);
  };

  return (
    <div className="top-bar">
      <div className={`search-bar ${dropdownVisible ? "open" : ""}`}>
        <FontAwesomeIcon icon={faSearch} className="search-icon" />
        <input
          type="text"
          placeholder="Sök..."
          aria-label="Search Team Files"
          value={searchQuery}
          onChange={handleSearchChange}
          onBlur={() => setTimeout(() => setDropdownVisible(false), 200)}
          onFocus={() => setDropdownVisible(searchQuery.length > 0)}
        />
        {dropdownVisible && (
          <div className="search-dropdown">
            {filteredResults.length > 0 ? (
              <ul className="search-results">
                {filteredResults.map((file, index) => (
                  <li
                    key={index}
                    className="search-result-item"
                    onMouseDown={() => handleFileSelect(file)}
                  >
                    {`${file.type}: ${file.id}`}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="muted-text empty-search-result">Inga sökresultat</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNavBar;
