import React from "react";
import styles from "./IconButton.module.css";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type IconButtonProps = {
  icon: IconDefinition;
  onClick: () => void;
  ariaLabel: string;
  size?: "xs" | "s" | "m" | "l";
  label?: string;
  active?: boolean;
};

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  ariaLabel,
  size = "s",
  label,
  active = false,
}) => {
  return (
    <div className={styles.iconButtonWrapper}>
      <button
        onClick={onClick}
        aria-label={ariaLabel}
        className={`${styles.iconButton} ${styles[size]} ${
          active ? styles.active : ""
        }`}
      >
        <FontAwesomeIcon icon={icon} aria-hidden="true" />
      </button>
      {label && <span className={styles.iconButtonLabel}>{label}</span>}
    </div>
  );
};

export default IconButton;
