import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import apiClient from "../../api/apiClient";
import { AutoLedgerTeamDTO, SystemUserExtendedDTO } from "../../types";
import { setAdminRights, setUserRights, resetRights } from "./resourceSlice";

interface UserState {
  user: SystemUserExtendedDTO | null;
  roles: string[];
  status: "idle" | "loading" | "failed";
  error: string | null;
}

const initialState: UserState = {
  user: null,
  roles: [],
  status: "idle",
  error: null,
};

export const fetchUser = createAsyncThunk<
  SystemUserExtendedDTO,
  void,
  { state: RootState }
>("user/fetchUser", async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await apiClient.get<SystemUserExtendedDTO>("/api/user/v1");
    const user = response.data;

    user.teams.forEach((team: AutoLedgerTeamDTO) => {
      dispatch(
        setAdminRights({
          teamId: team.id,
          isAdmin: team.admins.some((admin) => admin.id === user.id),
        })
      );
      dispatch(
        setUserRights({
          teamId: team.id,
          hasRights: team.users.some((u) => u.id === user.id),
        })
      );
    });

    return user;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch user"
    );
  }
});

export const createUser = createAsyncThunk<
  SystemUserExtendedDTO,
  void,
  { state: RootState }
>("user/createUser", async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await apiClient.post<SystemUserExtendedDTO>(
      "/api/user/v1",
      {}
    );
    const user = response.data;

    dispatch(resetRights());
    user.teams.forEach((team: AutoLedgerTeamDTO) => {
      dispatch(
        setAdminRights({
          teamId: team.id,
          isAdmin: team.admins.some((admin) => admin.id === user.id),
        })
      );
      dispatch(
        setUserRights({
          teamId: team.id,
          hasRights: team.users.some((u) => u.id === user.id),
        })
      );
    });

    return user;
  } catch (error: any) {
    if (error.response?.status === 409) {
      return rejectWithValue("User already exists");
    }
    return rejectWithValue(
      error.response?.data?.message || "Failed to create user"
    );
  }
});

export const deleteUser = createAsyncThunk<string, void, { state: RootState }>(
  "user/deleteUser",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await apiClient.delete("/api/user/v1");

      dispatch(resetRights());
      return "User deleted successfully";
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete user"
      );
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addTeamToUser(state, action: PayloadAction<AutoLedgerTeamDTO>) {
      if (state.user) {
        state.user = {
          ...state.user,
          teams: [...(state.user?.teams || []), action.payload],
        };
      }
    },
    removeTeamFromUser(state, action: PayloadAction<string>) {
      if (state.user) {
        state.user = {
          ...state.user,
          teams: state.user.teams?.filter(
            (team: AutoLedgerTeamDTO) => team.id !== action.payload
          ),
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.roles = action.payload.roles;
        state.status = "idle";
        state.error = null;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.roles = action.payload.roles;
        state.status = "idle";
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(deleteUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.user = null;
        state.roles = [];
        state.status = "idle";
        state.error = null;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { addTeamToUser, removeTeamFromUser } = userSlice.actions;
export default userSlice.reducer;
