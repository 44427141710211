import React, { useEffect } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { auth } from "./firebaseConfig";
import LoginPage from "./pages/LoginPage/LoginPage";
import TeamOverviewPage from "./pages/TeamOverviewPage/TeamOverviewPage";
import CreateAccountPage from "./pages/CreateAccountPage/CreateAccountPage";
import CreateTeamPage from "./pages/CreateTeamPage/CreateTeamPage";
import TeamRoomPage from "./pages/TeamRoomPage/TeamRoomPage";
import PrivateRoute from "./routes/PrivateRoute";
import GetStartedPage from "./pages/GetStartedPage/GetStartedPage";
import { setAuthenticated, logout } from "./store/slices/authSlice";
import { fetchUser } from "./store/slices/userSlice";
import { AppDispatch } from "./store/store";

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        dispatch(setAuthenticated(true));
        dispatch(fetchUser());
      } else {
        dispatch(logout());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/create-account" element={<CreateAccountPage />} />
        <Route path="/get-started" element={<GetStartedPage />} />
        <Route
          path="/team-overview"
          element={
            <PrivateRoute>
              <TeamOverviewPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/create-team"
          element={
            <PrivateRoute>
              <CreateTeamPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/team-room/:slug"
          element={
            <PrivateRoute>
              <TeamRoomPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
