import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser, faXmark } from "@fortawesome/free-solid-svg-icons";
import "./ModalMembers.css";
import IconButton from "../../../../components/Button/IconButton/IconButton";
import Button from "../../../../components/Button/Button";
import { PendingInvitationDTO, SystemUserDTO } from "../../../../types";

interface ModalMembersProps {
  selectedTeam: string;
  admins: Array<SystemUserDTO>;
  members: Array<SystemUserDTO>;
  pendingInvitations: Array<PendingInvitationDTO>;
  onClose: () => void;
  isAdmin: boolean;
  onDeleteInvitation: (invitationId: string) => Promise<void>;
}

const ModalMembers: React.FC<ModalMembersProps> = ({
  selectedTeam,
  admins,
  members,
  pendingInvitations = [],
  onClose,
  isAdmin,
  onDeleteInvitation,
}) => {
  const [confirmingId, setConfirmingId] = useState<string | null>(null);
  const [localPendingInvitations, setLocalPendingInvitations] = useState<
    PendingInvitationDTO[]
  >([]);

  useEffect(() => {
    setLocalPendingInvitations(pendingInvitations);
  }, [pendingInvitations]);

  const sortedMembers = [...members].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const sortedPendingInvitations = [...localPendingInvitations].sort((a, b) =>
    a.invitationEmail.localeCompare(b.invitationEmail)
  );

  const handleDeleteClick = (invitationId: string) => {
    setConfirmingId(invitationId);
  };

  const handleConfirmDelete = async (invitationId: string) => {
    try {
      await onDeleteInvitation(invitationId);
      setLocalPendingInvitations((prev) =>
        prev.filter((invitation) => invitation.id !== invitationId)
      );
    } catch (error) {
      console.error("Failed to delete invitation:", error);
    } finally {
      setConfirmingId(null);
    }
  };

  return (
    <div className="modal-background" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-members-header">
          <h3>{selectedTeam}</h3>
          <IconButton
            icon={faXmark}
            onClick={onClose}
            ariaLabel="close-button"
          />
        </div>
        <div className="modal-members-content">
          <ul className="members-list">
            {sortedMembers.map((member) => (
              <li key={member.id}>
                <div className="member-info">
                  <FontAwesomeIcon icon={faUser} /> {member.name}
                </div>
                {admins.some((admin) => admin.id === member.id) && (
                  <span className="admin-label">ADMIN</span>
                )}
              </li>
            ))}
          </ul>
          {isAdmin && (
            <ul className="pending-invitations-list">
              {sortedPendingInvitations.map((pendingInvitation) => (
                <li key={pendingInvitation.id}>
                  <div className="pending-invitation-info">
                    <FontAwesomeIcon icon={faEnvelope} />
                    {pendingInvitation.invitationEmail} (Inbjuden)
                  </div>
                  {confirmingId === pendingInvitation.id ? (
                    <Button
                      onClick={() => handleConfirmDelete(pendingInvitation.id)}
                      variant="primary"
                      ariaLabel="Bekräfta ta bort inbjudan"
                    >
                      Ta bort inbjudan
                    </Button>
                  ) : (
                    <IconButton
                      icon={faXmark}
                      onClick={() => handleDeleteClick(pendingInvitation.id)}
                      ariaLabel="Ta bort inbjudan"
                      size="s"
                    />
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="modal-members-footer">
          <Button
            variant="secondary"
            onClick={onClose}
            altText="Close modal button"
          >
            Stäng
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalMembers;
