import React from "react";
import "./Header.css";

interface HeaderProps {
  rightColumnContent?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ rightColumnContent }) => {
  return (
    <header
      className="header"
      role="banner"
      aria-label="Kameral AutoLedger Header"
    >
      <div className="left-col" aria-hidden="true"></div>
      <div className="center-col">
        <img
          src="https://kameral.se/wp-content/uploads/2024/04/kameral-badgetext.svg"
          alt="Kameral Logo"
          className="logo"
        />
      </div>
      <div className="right-col">{rightColumnContent}</div>
    </header>
  );
};

export default Header;
