import { useRef, useState } from "react";
import "./SideMenu.css";
import {
  faHome,
  faTrashCan,
  faUserPlus,
  faPlus,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import IconButton from "../../../../components/Button/IconButton/IconButton";
import { AutoLedgerTeamDTO } from "../../../../types";
import { useNavigate } from "react-router-dom";

interface SideMenuProps {
  teams: AutoLedgerTeamDTO[];
  selectedTeam: AutoLedgerTeamDTO;
  setInviteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideMenu: React.FC<SideMenuProps> = ({
  teams,
  selectedTeam,
  setInviteModalOpen,
  setDeleteModalOpen,
}) => {
  const [activeButton, setActiveButton] = useState<string | null>("home");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const teamLogoRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleCreateTeam = () => {
    navigate("/create-team");
  };

  const handleTeamSelect = (teamName: string) => {
    const selected = teams?.find(
      (team: AutoLedgerTeamDTO) => team.name === teamName
    );
    if (selected) {
      navigate(`/team-room/${selected.slug}`);
      setDropdownVisible(false);
    }
  };

  const filteredTeams =
    teams?.filter((team: AutoLedgerTeamDTO) => team.id !== selectedTeam.id) ||
    [];

  return (
    <div className="side-menu">
      <div className="icon-group">
        <div
          ref={teamLogoRef}
          className="team-logo-button"
          onClick={() => setDropdownVisible(!isDropdownVisible)}
        >
          {selectedTeam.name[0].toUpperCase()}
        </div>
        <Dropdown
          isVisible={isDropdownVisible}
          header={selectedTeam.name}
          options={[
            ...filteredTeams.map((t: AutoLedgerTeamDTO) => ({
              name: t.name,
            })),
            { name: "Skapa nytt team", icon: faPlus },
          ]}
          onOptionSelect={(option) => {
            if (option === "Skapa nytt team") {
              handleCreateTeam();
            } else {
              handleTeamSelect(option);
            }
          }}
          parentRef={teamLogoRef}
        />
        <IconButton
          ariaLabel="Home"
          icon={faHome}
          onClick={() => setActiveButton("home")}
          label="Hem"
          active={activeButton === "home"}
        />
        <IconButton
          ariaLabel="Invite Users"
          icon={faUserPlus}
          onClick={() => setInviteModalOpen(true)}
          label="Bjud in"
          active={activeButton === "addUser"}
        />
        <IconButton
          ariaLabel="Settings"
          icon={faTrashCan}
          onClick={() => setDeleteModalOpen(true)}
          label="Radera"
          active={activeButton === "settings"}
        />
      </div>
      <div className="bottom-icon">
        <IconButton
          ariaLabel="User Settings"
          icon={faUser}
          onClick={() => {}}
        />
      </div>
    </div>
  );
};

export default SideMenu;
