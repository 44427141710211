import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./TeamRoomPage.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store/store";
import Loader from "../../components/Loader/Loader";
import ModalInvitation from "./components/ModalInvitation/ModalInvitation";
import {
  addPendingInvitation,
  deleteTeamById,
  fetchTeamById,
  removePendingInvitation,
} from "../../store/slices/teamSlice";
import {
  selectUser,
  selectAuthStatus,
} from "../../store/selectors/authSelectors";
import {
  createInvitation,
  deleteInvitation,
} from "../../store/slices/invitationSlice";
import CollaborationArea from "./components/CollaborationArea/CollaborationArea";
import ModalMembers from "./components/ModalMembers/ModalMembers";
import ModalDeleteTeam from "./components/ModalDeleteTeam/ModalDeleteTeam";
import { removeTeamFromUser } from "../../store/slices/userSlice";
import {
  AutoLedgerTeamDTO,
  CreateInvitationDTO,
  DocumentDTO,
  ExpenseDTO,
} from "../../types";
import SideMenu from "./components/SideMenu/SideMenu";
import TopNavBar from "./components/TopNavBar/TopNavBar";
import NavigationArea from "./components/NavigationArea/NavigationArea";
import { uploadFilesToTeam } from "../../store/slices/rawDocumentsSlice";

const TeamRoomPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { selectedTeam, status } = useSelector(
    (state: RootState) => state.teams
  );
  const uploadProgress = useSelector(
    (state: RootState) => state.files.uploadProgress[selectedTeam?.id || ""]
  );
  const { uploadStatus } = useSelector((state: RootState) => state.files);
  const user = useSelector(selectUser);
  const authStatus = useSelector(selectAuthStatus);
  const teams = useMemo(() => user?.teams || [], [user]);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [isMembersModalOpen, setMembersModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [uploadingFileName, setUploadingFileName] = useState<string | null>(
    null
  );
  const [isNavigationAreaShrunk, setIsNavigationAreaShrunk] = useState(false);

  type SelectedFile =
    | (ExpenseDTO & { type: "Expense" })
    | (DocumentDTO & { type: "Document" })
    | null;

  type FileItem =
    | (ExpenseDTO & { type: "Expense" })
    | (DocumentDTO & { type: "Document" });

  const [selectedFile, setSelectedFile] = useState<SelectedFile>(null);

  useEffect(() => {
    if (!user && authStatus !== "loading") {
      navigate("/", { replace: true });
    } else if (slug && teams && authStatus === "idle") {
      const team = teams.find((t: AutoLedgerTeamDTO) => t.slug === slug);
      if (team) {
        setSelectedFile(null);
        dispatch(fetchTeamById(team.id));
      } else {
        navigate("/team-overview");
      }
    }
  }, [slug, teams, dispatch, user, authStatus, navigate]);

  const allFiles = useMemo(() => {
    return [
      ...(selectedTeam?.documents?.map((doc) => ({
        ...doc,
        type: "Document" as const,
      })) || []),
      ...(selectedTeam?.expenses?.map((exp) => ({
        ...exp,
        type: "Expense" as const,
      })) || []),
    ];
  }, [selectedTeam]);

  if (status === "loading" || !selectedTeam) {
    return <Loader message="Laddar team..." />;
  }

  const isUserAdmin = (): boolean => {
    if (!user || !selectedTeam) return false;
    return selectedTeam.admins.some((admin) => admin.id === user.id);
  };

  const handleSendInvite = (
    invitePayload: CreateInvitationDTO
  ): Promise<void> => {
    if (!selectedTeam) {
      console.error("No selected team found");
      return Promise.reject(new Error("No selected team found"));
    }
    return dispatch(createInvitation(invitePayload)).then((resultAction) => {
      if (createInvitation.fulfilled.match(resultAction)) {
        const newInvitation = resultAction.payload;
        const newPendingInvitation = {
          id: newInvitation.id,
          invitationEmail: invitePayload.inviteeEmail,
          inviter: user,
          invitationDate: new Date().toISOString(),
          isAdmin: invitePayload.shouldBeAdmin,
        };
        dispatch(addPendingInvitation(newPendingInvitation));
        return Promise.resolve();
      } else {
        const error = resultAction.payload || resultAction.error.message;
        return Promise.reject(new Error(error));
      }
    });
  };

  const handleDeleteInvitation = (invitationId: string) => {
    return dispatch(deleteInvitation(invitationId))
      .then((resultAction) => {
        if (deleteInvitation.fulfilled.match(resultAction)) {
          dispatch(removePendingInvitation(invitationId));
          return Promise.resolve();
        } else {
          const error = resultAction.payload || resultAction.error.message;
          return Promise.reject(new Error(error));
        }
      })
      .catch((error) => {
        console.error(
          "An error occurred while deleting the invitation:",
          error
        );
      });
  };

  const handleDeleteTeam = async () => {
    if (selectedTeam) {
      try {
        await dispatch(deleteTeamById(selectedTeam.id)).unwrap();
        dispatch(removeTeamFromUser(selectedTeam.id));
        navigate("/team-overview");
      } catch (error) {
        console.error("Failed to delete team:", error);
      }
    }
  };

  const handleSelectFile = (file: FileItem) => {
    const { id: fileId, type: fileType } = file;

    let fileData: ExpenseDTO | DocumentDTO | null = null;
    if (fileType === "Expense") {
      fileData =
        selectedTeam?.expenses?.find((exp) => exp.id === fileId) || null;
    } else if (fileType === "Document") {
      fileData =
        selectedTeam?.documents?.find((doc) => doc.id === fileId) || null;
    }
    if (fileData) {
      setSelectedFile({ ...fileData, type: fileType } as SelectedFile);
    }
  };

  const handleFiles = async (filesList: FileList) => {
    if (selectedTeam) {
      const fileName = filesList[0]?.name;
      setUploadingFileName(fileName);
      try {
        await dispatch(
          uploadFilesToTeam({ teamId: selectedTeam.id, files: filesList })
        );
      } catch (error) {
        console.error("Failed to upload files:", error);
      } finally {
        setUploadingFileName(null);
      }
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files: selectedFiles } = event.target;
    if (selectedFiles) {
      handleFiles(selectedFiles);
    }
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    if (event.dataTransfer.files) {
      handleFiles(event.dataTransfer.files);
    }
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  // console.log(selectedTeam);

  return (
    <div className="team-room-page">
      <div className="team-room-content">
        <TopNavBar files={allFiles} onSelectFile={handleSelectFile} />
        <div className="main-layout">
          <SideMenu
            teams={teams}
            selectedTeam={selectedTeam}
            setInviteModalOpen={setInviteModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
          />
          <div className="main-content">
            <NavigationArea
              selectedTeam={selectedTeam}
              files={allFiles}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onOpenMembers={() => setMembersModalOpen(true)}
              onFileInputChange={handleFileInputChange}
              uploadProgress={uploadProgress || 0}
              uploadStatus={uploadStatus}
              uploadingFileName={uploadingFileName}
              onSelectFile={handleSelectFile}
              size={isNavigationAreaShrunk ? "small" : "normal"}
            />
            <CollaborationArea
              selectedFile={selectedFile}
              setIsNavigationAreaShrunk={setIsNavigationAreaShrunk}
            />
          </div>
        </div>
      </div>
      {isInviteModalOpen && (
        <ModalInvitation
          onClose={() => setInviteModalOpen(false)}
          onSendInvite={(email, shouldBeAdmin) =>
            handleSendInvite({
              inviteeEmail: email,
              shouldBeAdmin,
              teamId: selectedTeam.id,
            })
          }
          isAdmin={isUserAdmin()}
        />
      )}
      {isMembersModalOpen && (
        <ModalMembers
          selectedTeam={selectedTeam.name}
          admins={selectedTeam.admins}
          members={selectedTeam.users}
          pendingInvitations={selectedTeam.pendingInvitations || []}
          onClose={() => setMembersModalOpen(false)}
          isAdmin={isUserAdmin()}
          onDeleteInvitation={handleDeleteInvitation}
        />
      )}
      {isDeleteModalOpen && (
        <ModalDeleteTeam
          teamName={selectedTeam.name}
          onClose={() => setDeleteModalOpen(false)}
          onDeleteTeam={handleDeleteTeam}
          isAdmin={isUserAdmin()}
        />
      )}
    </div>
  );
};

export default TeamRoomPage;
