import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import {
  getInvitation,
  consumeInvitation,
} from "../../store/slices/invitationSlice";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import Loader from "../../components/Loader/Loader";
import {
  selectIsAuthenticated,
  selectUser,
} from "../../store/selectors/authSelectors";
import "./GetStartedPage.css";
import { fetchUser, addTeamToUser } from "../../store/slices/userSlice";
import { getMemberText } from "../utils/helpers";
import {
  setAdminRights,
  setUserRights,
} from "../../store/slices/resourceSlice";
import { SystemUserDTO } from "../../types";
import { removePendingInvitation } from "../../store/slices/teamSlice";

const GetStartedPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invitationCode = queryParams.get("invitationCode");
  const user = useSelector(selectUser);

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [invitationData, setInvitationData] = useState<any | null>(null);

  useEffect(() => {
    if (isAuthenticated && invitationCode) {
      const fetchInvitation = async () => {
        try {
          setLoading(true);
          const result = await dispatch(
            getInvitation({ code: invitationCode })
          ).unwrap();
          setInvitationData(result);
          setError(null);
        } catch {
          setError("Misslyckades att hämta inbjudan.");
          setInvitationData(null);
        } finally {
          setLoading(false);
        }
      };
      fetchInvitation();
    }
  }, [invitationCode, dispatch, isAuthenticated]);

  const handleLogin = async () => {
    setLoading(true);
    setError(null);

    try {
      await signInWithPopup(auth, googleProvider);
      await dispatch(fetchUser()).unwrap();
      navigate(`/get-started?invitationCode=${invitationCode}`);
    } catch (error) {
      setError("Login failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleConsumeInvitation = async () => {
    if (!invitationCode || !invitationData) return;
    setLoading(true);
    try {
      await dispatch(consumeInvitation(invitationCode)).unwrap();

      dispatch(removePendingInvitation(invitationCode));
      dispatch(addTeamToUser(invitationData.autoLedgerTeam));

      dispatch(
        setAdminRights({
          teamId: invitationData.autoLedgerTeam.id,
          isAdmin: invitationData.isAdmin,
        })
      );
      dispatch(
        setUserRights({
          teamId: invitationData.autoLedgerTeam.id,
          hasRights: invitationData.autoLedgerTeam.users.some(
            (u: SystemUserDTO) => u.id === user!.id
          ),
        })
      );

      navigate(`/team-room/${invitationData.autoLedgerTeam.slug}`);
    } catch (error: any) {
      console.error("Error detail:", error);
      setError("Failed to accept the invitation.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader message="Laddar inbjudan..." />;
  }

  return (
    <div className="get-started-page">
      <Header />
      <div className="get-started-page-content">
        {!isAuthenticated && (
          <div className="authenticate-section">
            <h1>Hmm, det ser inte ut som att du är inloggad!</h1>
            <Button
              variant="google-primary"
              onClick={handleLogin}
              className="login-button"
              altText="Authentication button"
            >
              Logga in med Google
            </Button>
            <div className="divider">
              <span className="divider-text">ELLER</span>
            </div>
            <Button
              variant="google-secondary"
              onClick={() =>
                navigate(`/create-account?invitationCode=${invitationCode}`)
              }
              className="create-account-button"
              altText="Create account button"
            >
              Skapa konto med Google
            </Button>
          </div>
        )}
        {error && <p className="error-message">{error}</p>}
        {isAuthenticated &&
          !error &&
          invitationData &&
          invitationData.autoLedgerTeam && (
            <div className="invitation-section">
              <div className="invitation-title">
                <h3>Gå med i teamet!</h3>
                <span>
                  Du har blivit inbjuden att samarbeta i{" "}
                  <strong>{invitationData.autoLedgerTeam.name}</strong>
                </span>
              </div>
              <div className="invitation">
                <div className="invitation-team-logo">
                  {invitationData.autoLedgerTeam.name[0].toUpperCase()}
                </div>
                <div className="invitation-team-name">
                  <h3>{invitationData.autoLedgerTeam.name}</h3>
                </div>
                <Link
                  to={`/team-room/${invitationData.autoLedgerTeam.slug}`}
                  className="invitation-link muted-text"
                >
                  workspace.kameral.se/#/team-room/
                  {invitationData.autoLedgerTeam.slug}
                </Link>
                <Button
                  variant="primary"
                  onClick={handleConsumeInvitation}
                  altText="Accept invitation"
                  className="accept-invite-button"
                >
                  Acceptera inbjudan
                </Button>
                <div className="divider" />
                <span>
                  <strong>
                    {invitationData.autoLedgerTeam.users.length}{" "}
                    {getMemberText(invitationData.autoLedgerTeam.users.length)}{" "}
                    väntar på dig!
                  </strong>
                </span>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default GetStartedPage;
